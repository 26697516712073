<style lang="scss" scoped>
  /deep/ .comp-article-edit {
    max-width: 450px;
  }
</style>

<template>
    <div>
        <el-dialog :title="titlePrev + '类别'" custom-class="c-el-dialog comp-article-edit" :visible.sync="showDialog" :before-close="_close" :close-on-click-modal="false">
            <el-form :model="dialogData" :rules="formRules" :ref="formRefName" label-width="100px" size="small">
                <el-row :gutter="10">
                    <el-col :xs="24">
                        <el-form-item label="名称" prop="name">
                            <el-input v-model="dialogData.name" placeholder="请输入名称"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="描述" prop="desc">
                            <el-input type="textarea" rows="5" v-model="dialogData.desc" placeholder="请输入描述"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="排序" prop="sort">
                            <el-input v-model="dialogData.sort"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer">
                <el-button size="small" @click="_close()">取消</el-button>
                <el-button size="small" type="primary" @click="_save()">提交</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import configMixins from "@/mixins/config"
import * as funCommon from "@/api/all"
export default {
    name: "compArticleEdit",
    mixins: [configMixins],
    data() {
        return {
            showDialog: false,
            titlePrev: "",
            dialogType: "",
            defaultData: {
                type: 3,
                name: "",
                desc: "",
                sort: 0,
                id: 0
            },
            dialogData: {},
            formRefName: "refArticleEditForm",
            formRules: {
                name: [{ required: true, message: "请输入名称", trigger: "blur" }],
                sort: [{ required: true, message: "请输入排序", trigger: "blur" }]
            }
        }
    },
    methods: {
        show(row) {
            this.dialogData = JSON.parse(JSON.stringify(this.defaultData))
            this.dialogType = row ? this.createOrUpdate[1] : this.createOrUpdate[0]
            if (this.dialogType == this.createOrUpdate[0]) {
                // 新增
                this.titlePrev = "新增"
                this.showDialog = true
            } else {
                // 修改
                this.titlePrev = "修改"
                this.dialogData.id = row.id
                window.$common.fullLoading()
                funCommon.CategoryDetail({ id: this.dialogData.id }).then(res => {
                    window.$common.closeFullLoading()
                    for (let i in this.dialogData) {
                        this.dialogData[i] = res[i]
                    }
                    this.showDialog = true
                })
            }
        },
        _save() {
            this.$refs[this.formRefName].validate((valid) => {
                if (valid) {
                    let commitData = JSON.parse(JSON.stringify(this.dialogData))
                    window.$common.fullLoading()
                    if (this.dialogType == this.createOrUpdate[0]){
                        // 创建
                        funCommon.CategoryCreate(commitData).then(() => {
                            this.commonSuccess()
                        })
                    } else {
                        // 更新
                        funCommon.CategoryUpdate(commitData).then(() => {
                            this.commonSuccess()
                        })
                    }
                } else {
                    console.log('error submit!')
                    return false
                }
            })
        },
        _close() {
            this.showDialog = false
            this.$nextTick(() => {
                if (this.$refs[this.formRefName]) {
                    this.$refs[this.formRefName].resetFields()
                }
            })
        },
        commonSuccess(val) {
            let tips = val || "操作成功!"
            this.successMsg(tips, {
                onClose: () => {
                    this._close()
                    window.$common.closeFullLoading()
                    this.refreshData()
                }
            })
        },
        refreshData() {
            this.$emit("refreshData", true)
        }
    }
}
</script>
<style lang="scss" scoped></style>

<template>
    <div class="page-article-classify">
        <div class="toolbars mrgb5">
            <div class="buttons mrgb5">
                <div class="fl">
                    <el-button class="mrgb5" type="primary" icon="el-icon-plus" @click="_edit()" size="medium">添加类别</el-button>
                </div>
                <div class="clearb"></div>
            </div>
        </div>
        <div>
            <el-table :data="tableData" border fit highlight-current-row row-key="id">
                <el-table-column prop="id" label="ID" width="80"></el-table-column>
                <el-table-column prop="name" label="姓名"></el-table-column>
                <el-table-column prop="sort" label="排序"></el-table-column>
                <el-table-column prop="desc" label="描述"></el-table-column>
                <el-table-column label="操作" width="100px" fixed="right">
                    <template slot-scope="scope">
                        <el-dropdown trigger="click">
                            <el-button type="primary" plain size="mini">更多
                                <i class="el-icon-arrow-down el-icon--right"></i>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item @click.native="_edit(scope.row)">修改</el-dropdown-item>
                                <el-dropdown-item @click.native="_delete(scope.row)">删除</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination-container" v-if="isPagination">
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="filter.page" :page-sizes="appPageSizes" :page-size="filter.size"
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>
        <!--编辑分类-->
        <edit :ref="refEdit" @refreshData="_search()"></edit>
    </div>
</template>

<script>
import * as funCommon from "@/api/all"
import edit from "./components/classifyEdit"
export default {
    name: "pageArticleClassify",
    components: { edit },
    data() {
        return {
            refEdit: "refClassifyToEdit",
            tableItems: {},
            tableData: [],
            total: null,
            isPagination: false,
            filter: {
                page: 1,
                size: window.$common.appPageSize,
                type: 3
            }
        }
    },
    activated() {
        this.getDataList()
    },
    created() {
        this.getDataList()
    },
    methods: {
        handleSizeChange(val) {
            this.filter.size = val
            this.getDataList()
        },
        handleCurrentChange(val) {
            this.filter.page = val
            this.getDataList()
        },
        async getDataList() {
            window.$common.fullLoading()
            funCommon.CategoryList(this.filter).then(response => {
                window.$common.closeFullLoading()
                this.tableItems = response
                this.total = response.totalCount
                this.isPagination = response.totalCount > 0 || false
                if (response && response.items && response.items.length > 0) {
                this.tableData = response.items
                } else {
                this.tableData = []
                }
            })
        },
        _edit(row) {
            this.$refs[this.refEdit].show(row)
        },
        _delete(row) {
            this.confirm("确认要删除该类别吗?").then(() => {
                window.$common.fullLoading()
                funCommon.Categoryremove({ id: row.id }).then(res => {
                    window.$common.closeFullLoading()
                    this.successMsg("删除成功")
                    this.getDataList()
                })
            })
        },
        _search() {
            this.filter.page = 1
            this.getDataList()
        }
    }
}
</script>